import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import { Button } from "react-bootstrap";

const NotFoundPage = () => (
  <Layout>
    <div className="container content text-center">
      <section className="section">
        <h1>404 - Not Found</h1>
        <p>Oops - You just found a page that doesn&#39;t exist...</p>
        <div className="my-5">
          <Link to="/">
            <Button variant="outline-primary">
              &larr; Return to homepage?
            </Button>
          </Link>
        </div>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;
